import React from "react"

import { Stack, Text, TextLink } from "@kiwicom/orbit-components"

import Container from "components/container"
import Hero from "components/hero"
import Layout from "components/layout"
import SeparatorH2Style from "components/separator-h2-style"

const Page = () => (
  <Layout
    title="Charging"
    description="Ember's charging network is designed for buses, coaches and other large vehicles. Find out how we're enabling third parties to benefit from this infrastructure."
  >
    <Hero title="Charge with Ember" />
    <Container size="narrow">
      <SeparatorH2Style>
        <Stack>
          <Text>
            Ember is building a network of ultra-fast charging hubs across
            Scotland. They're designed for our own fleet but we're delighted to
            be opening this network to other operators of buses, coaches and
            HGVs, helping them to go electric.
          </Text>
          <h2>Locations</h2>
          <Text>Our first hub in Dundee is now live.</Text>
          <Text>
            We have further hubs coming soon, including in Aberdeen, Inverness,
            Fort William and Paisley. Keep an eye on this page for updates.
          </Text>
          <h2>Access & Terms</h2>
          <Text>
            Email{" "}
            <TextLink href="mailto:ride@ember.to?subject=Charging Enquiry">
              ride@ember.to
            </TextLink>{" "}
            if you are interested in using our network for your bus, coach or
            HGV .
          </Text>
          <Text>
            Our chargers offer CCS cables with speeds of up to 300kW. Chademo
            and AC charging is not available. Following your enquiry, a
            scheduled slot will be provided that guarantees availability within
            a particular period. This can either be a one-off slot, or an
            ongoing arrangement, depending on your needs.
          </Text>
          <Text>
            Charging costs £10/hour for your reserved slot and 35p/kWh for
            electricity usage. Costs and billing structure are subject to change
            and will be confirmed following your enquiry.
          </Text>
        </Stack>
      </SeparatorH2Style>
    </Container>
  </Layout>
)

export default Page
